import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {apiVersion} from '../../../api-version.constant';
import {DataPointListColumns} from '../../../device-management/device-details/common/data-points-and-filters/data-points/toolbar/toolbar.static';
import {ThemingService} from '../../services/theming.service';
import {getCacheHeaders} from '../shared.service';

export enum DataPointDisplay {
  LABEL = 'label',
  SYSTEM_NAME = 'systemName',
  LABEL_WITH_SYSTEM_NAME = 'labelWithSystemName',
  SYSTEM_NAME_WITH_LABEL = 'systemNameWithLabel'
}

export enum DataPointGrouping {
  DATA_TYPE = 'dataType',
  OBJECT_TYPE = 'objectType'
}

export enum DataPointType {
  ALL_DATA_POINTS = 'allDataPoints',
  REAL_DATA_POINTS = 'realDataPoints',
  CUSTOM_DATA_POINTS = 'customDataPoints'
}

export enum UserTheme {
  SYSTEM_DEFAULT = 0,
  LIGHT = 1,
  DARK = 2
}

export enum SchematicFaceplateView {
  COMPACT = 'COMPACT',
  EXTENDED = 'EXTENDED'
}

export const DataPointListPreferences: Record<DataPointListColumns, keyof UserPreferencesI> = {
  [DataPointListColumns.LABEL]: 'dataPointFiltersShowLabel',
  [DataPointListColumns.SYSTEM_NAME]: 'dataPointFiltersShowSystemName',
  [DataPointListColumns.EXTERNAL_ID]: 'dataPointFiltersShowExternalId',
  [DataPointListColumns.BUS_ADDRESS]: 'dataPointFiltersShowBusAddress',
  [DataPointListColumns.DATA_TYPE]: 'dataPointFiltersShowDataType',
  [DataPointListColumns.TYPE]: 'dataPointFiltersShowType',
  [DataPointListColumns.LIVE_VALUE]: 'dataPointFiltersShowLiveValues',
  [DataPointListColumns.UNIT]: 'dataPointFiltersShowUnit',
  [DataPointListColumns.STATE_FLAGS]: 'dataPointFiltersShowStateFlags',
  [DataPointListColumns.STATUS_FLAGS]: 'dataPointFiltersShowBACnetStatus',
  [DataPointListColumns.USAGES]: 'dataPointFiltersShowPlatformUsage',
  [DataPointListColumns.LICENSES]: 'dataPointFiltersShowPlatformLicenses',
  [DataPointListColumns.PROFILE]: 'dataPointFiltersShowProfile',
  [DataPointListColumns.TAGS]: 'dataPointFiltersShowTags',
  [DataPointListColumns.DISPLAY]: 'dataPointFiltersShowDisplay'
};

export interface UserPreferencesI {
  bacnetPropertiesEnglishLanguage: boolean;
  cdpSidePanels: string;
  dataPointDisplay: DataPointDisplay;
  dataPointFiltersShowLabel: boolean;
  dataPointFiltersShowSystemName: boolean;
  dataPointFiltersShowExternalId: boolean;
  dataPointFiltersShowBusAddress: boolean;
  dataPointFiltersShowDataType: boolean;
  dataPointFiltersShowType: boolean;
  dataPointFiltersShowLiveValues: boolean;
  dataPointFiltersShowUnit: boolean;
  dataPointFiltersShowPlatformUsage: boolean;
  dataPointFiltersShowPlatformLicenses: boolean;
  dataPointFiltersShowStateFlags: boolean;
  dataPointFiltersShowBACnetStatus: boolean;
  dataPointFiltersShowDisplay: boolean;
  dataPointFiltersShowProfile: boolean;
  dataPointFiltersShowTags: boolean;
  dataPointGrouping: DataPointGrouping;
  dataPointTagIds: string;
  dataPointType: DataPointType;
  devicesGridView?: boolean;
  documentsGridList: boolean;
  globalSidePanelDataPointListLeft: number;
  globalSidePanelBillingLeft: number;
  globalSidePanelDeviceDetailsLeft: number;
  globalSidePanelTicketListLeft: number;
  gnssTrackingSidePanels: string;
  groupNavigationWidth: number;
  languageChain: string;
  mediaFlowSidePanels: string;
  meterNetworkSidePanels: string;
  resizableMainWithSidebarDataPointFilters: string;
  resizableMainWithSidebarObjectsOnTheNetwork: string;
  resizableMainWithSidebarFolders: string;
  resizableMainWithSidebarShapeEditor: string;
  resizableMainWithSidebarConnectionEditor: string;
  resizableMainWithSidebarMasterPages: string;
  resizableMainWithSidebarOnSitePanelExportSets: string;
  resizableMainWithSidebarBilling: string;
  resizableMainWithSidebarUserManagement: string;
  resizableMainWithSidebarAlertings: string;
  resizableMainWithSidebarRecordings: string;
  resizableMainWithSidebarDevices: string;
  resizableMainWithSidebarCDP: string;
  resizableMainWithSidebarMeterNetwork: string;
  resizableMainWithSidebarGNSSTracking: string;
  resizableMainWithSidebarFleetManagement: string;
  scheduleView?: string;
  schematicCustomPropertiesExpanded: boolean;
  schematicDataPointPropertiesExpanded: boolean;
  schematicDocumentsExpanded: boolean;
  schematicFaceplateView?: SchematicFaceplateView;
  schematicLinkPropertiesExpanded: boolean;
  schematicMasterPagesExpanded: boolean;
  schematicPagePropertiesExpanded: boolean;
  schematicShapePropertiesExpanded: boolean;
  schematicSidePanels: string;
  showLogLogin: boolean;
  showLogManual: boolean;
  showLogRecord: boolean;
  showLogUpdate: boolean;
  synchronizeByObjectName: boolean;
  theme?: number;
  ticketListConfig: string;
  uploadFileSizeLimit: number;
}

export const UserPreferences: UserPreferencesI = {
  bacnetPropertiesEnglishLanguage: false,
  cdpSidePanels: '{}',
  dataPointDisplay: DataPointDisplay.LABEL_WITH_SYSTEM_NAME,
  dataPointFiltersShowLabel: true,
  dataPointFiltersShowSystemName: true,
  dataPointFiltersShowExternalId: false,
  dataPointFiltersShowBusAddress: false,
  dataPointFiltersShowDataType: true,
  dataPointFiltersShowType: false,
  dataPointFiltersShowLiveValues: true,
  dataPointFiltersShowUnit: true,
  dataPointFiltersShowPlatformUsage: false,
  dataPointFiltersShowPlatformLicenses: false,
  dataPointFiltersShowStateFlags: false,
  dataPointFiltersShowBACnetStatus: false,
  dataPointFiltersShowDisplay: false,
  dataPointFiltersShowProfile: false,
  dataPointFiltersShowTags: false,
  dataPointGrouping: DataPointGrouping.DATA_TYPE,
  dataPointTagIds: '[]',
  dataPointType: DataPointType.ALL_DATA_POINTS,
  devicesGridView: false,
  documentsGridList: false,
  globalSidePanelDataPointListLeft: 0,
  globalSidePanelBillingLeft: 0,
  globalSidePanelDeviceDetailsLeft: 0,
  globalSidePanelTicketListLeft: 0,
  gnssTrackingSidePanels: '{}',
  groupNavigationWidth: 0,
  languageChain: '',
  mediaFlowSidePanels: '{}',
  meterNetworkSidePanels: '{}',
  resizableMainWithSidebarDataPointFilters: '{}',
  resizableMainWithSidebarObjectsOnTheNetwork: '{}',
  resizableMainWithSidebarFolders: '{}',
  resizableMainWithSidebarShapeEditor: '{}',
  resizableMainWithSidebarConnectionEditor: '{}',
  resizableMainWithSidebarMasterPages: '{}',
  resizableMainWithSidebarOnSitePanelExportSets: '{}',
  resizableMainWithSidebarBilling: '{}',
  resizableMainWithSidebarUserManagement: '{}',
  resizableMainWithSidebarAlertings: '{}',
  resizableMainWithSidebarRecordings: '{}',
  resizableMainWithSidebarDevices: '{}',
  resizableMainWithSidebarCDP: '{}',
  resizableMainWithSidebarMeterNetwork: '{}',
  resizableMainWithSidebarGNSSTracking: '{}',
  resizableMainWithSidebarFleetManagement: '{}',
  scheduleView: '',
  schematicCustomPropertiesExpanded: true,
  schematicDataPointPropertiesExpanded: true,
  schematicDocumentsExpanded: true,
  schematicFaceplateView: SchematicFaceplateView.COMPACT,
  schematicLinkPropertiesExpanded: true,
  schematicMasterPagesExpanded: true,
  schematicPagePropertiesExpanded: true,
  schematicShapePropertiesExpanded: true,
  schematicSidePanels: '{}',
  showLogLogin: true,
  showLogManual: true,
  showLogRecord: true,
  showLogUpdate: true,
  synchronizeByObjectName: false,
  theme: UserTheme.SYSTEM_DEFAULT,
  ticketListConfig: '',
  uploadFileSizeLimit: 0
};

export const userPreferencesChanged: Subject<Partial<UserPreferencesI>> = new Subject<Partial<UserPreferencesI>>();

@Injectable({providedIn: 'root'})
export class UserPreferencesEntity {
  constructor(
    private http: HttpClient,
    private themingService: ThemingService
  ) {}

  load(cache?: boolean): Observable<UserPreferencesI> {
    // Load user preferences.
    return this.http.get(apiVersion + 'userSettings', getCacheHeaders(cache)).pipe(
      map((response: UserPreferencesI) => {
        Object.assign(UserPreferences, response);
        // Because all preferences are stored as text, we manually convert number and boolean strings
        // into actual numbers and boolean.
        for (const property of Object.keys(UserPreferences)) {
          if (UserPreferences[property] === 'true') {
            UserPreferences[property] = true;
          } else if (UserPreferences[property] === 'false') {
            UserPreferences[property] = false;
          } else if (!isNaN(UserPreferences[property])) {
            UserPreferences[property] = Number(UserPreferences[property]);
          }

          if (property === 'theme') {
            this.themingService.setUserTheme(UserPreferences[property]);
          }
        }

        return UserPreferences;
      })
    );
  }

  /**
   * Partial save only for properties changed.
   * @param settings
   */
  save(settings: Partial<UserPreferencesI>): Observable<any> {
    Object.assign(UserPreferences, settings);

    userPreferencesChanged.next(settings);

    // Save user preferences.
    return this.http.post<any>(apiVersion + 'userSettings', {settings});
  }
}
