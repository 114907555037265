import {DataPoint} from '../../../../../../shared/entities/data-points/data-points.interface';
import {ProtocolTypes} from '../../../../../../shared/properties/protocol-types.enum';
import {DataPoint as DataPointExtended} from '../data-point.interface';

export function canMergeDataPoints(selectedDataPoints: DataPointExtended[]): boolean {
  const mergeDataPoints = selectedDataPoints.filter(dataPoint => dataPoint.alcedoTypeId === selectedDataPoints[0].alcedoTypeId);
  return mergeDataPoints.length === selectedDataPoints.length;
}

export function hasPublishedDataPoints(selectedDataPoints: DataPointExtended[]): boolean {
  return selectedDataPoints.findIndex((dp: DataPointExtended) => dp.protocolId !== ProtocolTypes.BACNET && dp.publishedInstanceId !== undefined) > -1;
}

export function hasNoUnitDataPoints(selectedDataPoints: DataPoint[]): boolean {
  return (
    selectedDataPoints.filter(dp => (dp.unitId === -1 || dp.unitId === 1001 || dp.unitId === 95) && dp.displayUnitId === -1 && dp.customUnit === null)
      .length === selectedDataPoints.length
  );
}

export enum DataPointListColumns {
  LABEL = 'name',
  SYSTEM_NAME = 'systemName',
  EXTERNAL_ID = 'externalErpId',
  BUS_ADDRESS = 'busAddress',
  DATA_TYPE = 'dataType',
  TYPE = 'type',
  LIVE_VALUE = 'liveValue',
  UNIT = 'unit',
  STATE_FLAGS = 'stateFlags',
  STATUS_FLAGS = 'statusFlags',
  USAGES = 'usages',
  LICENSES = 'licenses',
  PROFILE = 'profile',
  TAGS = 'tags',
  DISPLAY = 'visible'
}
